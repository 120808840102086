import {
  Flex,
  Heading,
  Icon,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";

import mask from "../../assets/about/header/wave.svg";
import landing from "../../assets/about/header/landing.webm";

import { FaCircle } from "react-icons/fa";

// blink animation for white circle
const blink = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(0px) scale(0.85);
    opacity: 0.75;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

function Header() {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${blink} infinite 3s ease-in-out`;

  return (
    <Flex position="relative" w="100%" h="100vh" overflow="hidden">
      {/* wave-shaped svg mask */}
      <Flex
        style={{
          maskImage: `url(${mask})`,
          maskSize: "cover",
          maskPosition: "bottom center",
          maskRepeat: "no-repeat",
        }}
        position="absolute"
        inset={0}
      >
        {/* gradient overlay */}
        <Flex
          position="absolute"
          inset={0}
          w={"100%"}
          h={"100%"}
          background={"linear-gradient(to top, #6985c1 30%, transparent)"}
          opacity={"0.5"}
          zIndex={1}
        />

        {/* landing video */}
        <Flex
          as={"video"}
          w={"100%"}
          h={"100%"}
          src={landing}
          objectFit={"cover"}
          objectPosition={"top center"}
          autoPlay
          muted
          loop
        />
      </Flex>

      {/* content */}
      <Flex
        direction={"column-reverse"}
        zIndex={3}
        mx={"auto"}
        align={"center"}
        gap={4}
        justify={"center"}
      >
        <Icon
          as={FaCircle}
          boxSize={{ lg: 10, xl: 16 }}
          color={"white"}
          animation={animation}
          mt={"46px"}
        />
        <Heading
          as="h1"
          fontSize={{ lg: "26px", xl: "30px", "2xl": "36px" }}
          fontWeight={"bold"}
          color={"white"}
          mb={0}
          textAlign={"center"}
        >
          We are creating EmpathicAI, AI at the service of humanity
        </Heading>
      </Flex>
    </Flex>
  );
}

export default Header;
