import {
  Button,
  Flex,
  Heading,
  Icon,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import AnimatedButton from "../../shared/AnimatedButton";

import mask from "../../assets/home/header/wave.svg";
import landing from "../../assets/home/header/landing.webm";

import { FaCircle } from "react-icons/fa";
import { LuExternalLink } from "react-icons/lu";

// blink animation for white circle
const blink = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(0px) scale(0.85);
    opacity: 0.75;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

function Header() {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${blink} infinite 3s ease-in-out`;

  return (
    <Flex position="relative" w="100%" h="100vh" overflow="hidden">
      {/* wave-shaped svg mask */}
      <Flex
        style={{
          maskImage: `url(${mask})`,
          maskSize: "cover",
          maskPosition: "bottom left",
          maskRepeat: "no-repeat",
        }}
        position="absolute"
        inset={0}
      >
        {/* gradient overlay */}
        <Flex
          position="absolute"
          inset={0}
          w={"100%"}
          h={"100%"}
          background={"linear-gradient(to top, #6985c1 30%, transparent 70%)"}
          opacity={"0.5"}
          zIndex={1}
        />

        {/* landing video */}
        <Flex
          as={"video"}
          w={"100%"}
          h={"100%"}
          src={landing}
          objectFit={"cover"}
          objectPosition={"top center"}
          autoPlay
          muted
          loop
        />
      </Flex>

      {/* content */}
      <Flex zIndex={3} gap={4} mx={"auto"}>
        {/* text + circle */}
        <Flex align={"center"} gap={4} justify={"center"}>
          <Icon
            as={FaCircle}
            boxSize={{ lg: 10, xl: 16 }}
            color={"white"}
            animation={animation}
            mt={"36px"}
          />
          <Heading
            as="h1"
            fontSize={{ lg: "26px", xl: "30px", "2xl": "36px" }}
            fontWeight={"bold"}
            color={"white"}
            mb={0}
            textAlign={"center"}
          >
            Connecting the dots with empathetic AI <br />
            accelerating scientific discoveries
          </Heading>
        </Flex>

        {/* buttons */}
        <ActionButtons />
      </Flex>
    </Flex>
  );
}

function ActionButtons() {
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"flex-end"}
      w={"fit-content"}
      pt={"200px"}
      gap={4}
    >
      {/* Social Media Button */}
      <Button
        w={"180px"}
        size={{ lg: "md", xl: "lg" }}
        variant={"outline"}
        color={"white"}
        borderColor={"white"}
        _hover={{ bg: "transparent", color: "white", borderColor: "white" }}
        rightIcon={<LuExternalLink />}
      >
        Social media
      </Button>

      {/* Contact Us Button */}
      <AnimatedButton
        w={"180px"}
        size={{ lg: "md", xl: "lg" }}
        color={"white"}
        bgGradient={
          "linear-gradient(144.12deg, #57AADE -0.73%, #205489 63.64%, #202053 128.02%)"
        }
        boxShadow={"0px 4px 9px 0px #00000040"}
        _hover={{ bg: "linear(to-b, #2088BE 0%, #0F3F58 100%)" }}
      >
        Contact us
      </AnimatedButton>
    </Flex>
  );
}

export default Header;
