import { useEffect } from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import BackToTop from "../../components/ui/BackToTop";

export default function InvestorPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"transparent"}>
      <BackToTop />
      <Box width="100%" maxW="1200px" margin="auto" pt="112px" px={4}>
        <Heading
          as={"h2"}
          bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
          bgClip="text"
          color="transparent"
          fontFamily="Poppins, sans-serif"
          lineHeight="1.3"
          fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
          w={"fit-content"}
        >
          Investor Relations
        </Heading>
        <Divider mt={4} />

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>
      </Box>
    </Flex>
  );
}
