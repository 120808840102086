import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  SimpleGrid,
  Divider,
  Flex,
  Icon,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { MdArrowOutward } from "react-icons/md";

export default function OurPartners() {
  const PARTNERS = [
    {
      name: "Peter Owotoki",
      title: "Co-Founder & CEO",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/martin_doper.jpeg",
      social: "https://www.linkedin.com/in/peter-owotokidr-ing/",
    },
    {
      name: "Martin Dober",
      title: "Co-Founder & COO",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/martin_doper.jpeg",
      social: "https://www.linkedin.com/in/dober-martin/",
    },
    {
      name: "Wamuyu Owotoki",
      title: "Co-Founder & CEO, iZola.life",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/empathicai/wamuyu.jpeg",
      social: "https://www.linkedin.com/in/wamuyu/",
    },
    {
      name: "Mary McDavid",
      title: "Co-Founder & Advisory Board",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/empathicai/wamuyu.jpeg",
      social: "https://www.linkedin.com/in/wamuyu/",
    },
    {
      name: "Jane Doe",
      title: "Places",
      imgSrc:
        "https://vf-website-assets.s3.eu-central-1.amazonaws.com/martin_doper.jpeg",
      social: "https://www.linkedin.com/in/wamuyu/",
    },
  ];

  const goToUrl = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Box width="100%" maxW="1200px" margin="auto" pt="64px" pb="112px" px={4}>
      <Heading
        as={"h2"}
        bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
        bgClip="text"
        color="transparent"
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"fit-content"}
      >
        Venture Partners
      </Heading>

      <Divider mt={4} />

      <SimpleGrid mt={4} columns={[2, null, 4]} spacing="20px">
        {PARTNERS.map((partner: any, index: number) => (
          <Card
            height="fit-content"
            direction="column"
            overflow="hidden"
            variant="outline"
            key={`${partner.name}-${index}`}
          >
            <Image
              objectFit="cover"
              maxW="100%"
              src={partner.imgSrc}
              alt={`${partner.name} profile image`}
            />

            <Stack>
              <CardBody>
                <Flex justify="space-between" align="center" mb={2}>
                  <Heading
                    size="md"
                    cursor="pointer"
                    onClick={() => goToUrl(partner.social)}
                  >
                    {partner.name}
                  </Heading>
                  <Icon
                    cursor="pointer"
                    onClick={() => goToUrl(partner.social)}
                    as={MdArrowOutward}
                  />
                </Flex>
                <Text py="2" color={"gray.500"}>
                  {partner.title}
                </Text>
              </CardBody>
            </Stack>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  );
}
