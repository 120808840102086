import { useState } from "react";
import { motion } from "framer-motion";

import { Button, ButtonProps } from "@chakra-ui/react";
import { FaAngleRight, FaArrowRight } from "react-icons/fa";

interface AnimatedButtonProps extends ButtonProps {
  icon?: JSX.Element;
  hoveredIcon?: JSX.Element;
}

function AnimatedButton({
  icon = <FaAngleRight size={"16px"} />,
  hoveredIcon = <FaArrowRight size={"16px"} />,
  children,
  ...rest
}: AnimatedButtonProps) {
  const [hovered, setHovered] = useState<boolean>(false);

  const buttonVariants = {
    hovered: { x: 2, transition: { duration: 0.5 } },
    unhovered: { x: -2 },
  };

  return (
    <Button
      rightIcon={
        <motion.div
          transition={{ type: "spring", stiffness: 100, damping: 10 }}
          initial={hovered ? "hovered" : "unhovered"}
          animate={hovered ? "hovered" : "unhovered"}
          variants={buttonVariants}
        >
          {hovered ? hoveredIcon : icon}
        </motion.div>
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default AnimatedButton;
