import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import { Flex, Box, ChakraProvider, useMediaQuery } from "@chakra-ui/react";

// Shared imports
import Nav from "./shared/nav";

// Views imports
import Home from "./views/home";
import About from "./views/about";
import Team from "./views/team";
import Investor from "./views/investor";
import PageNotFound from "./views/PageNotFound";

// Theme imports
import generateTheme from "./theme";
import MobileView from "./views/MobileView";

function Layout() {
  const borderColor = "transparent";

  return (
    <Flex
      direction="column"
      position={"relative"}
      bg={"background"}
      p={0}
      minHeight={"100vh"}
    >
      {/* navbar */}
      <Box
        as="header"
        position="fixed"
        width="100%"
        zIndex="10"
        padding={["32px 18px 0", "32px 72px 0"]}
        bg="transparent"
      >
        <Nav />
      </Box>

      {/* main panel */}
      <Box
        as="main"
        w={"100%"}
        borderColor={borderColor}
        bg={"background"}
        flex="1"
        p="0"
        mt="0"
      >
        <Outlet />
      </Box>
    </Flex>
  );
}

function AppWrapper() {
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  if (isMobileView) return <MobileView isMobileView={isMobileView} />;

  return (
    <Box visibility={!isMobileView ? "visible" : "hidden"}>
      <Outlet />
    </Box>
  );
}

export default function AppRoutes() {
  // Theme
  const theme = generateTheme();

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<AppWrapper />}>
            {/* Public routes */}
            <Route element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="team" element={<Team />} />
              <Route path="investor-relations" element={<Investor />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
