import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import Header from "../../components/home/Header";
import BackToTop from "../../components/ui/BackToTop";
import OurMission from "../../components/home/OurMission";
import WhatWeDo from "../../components/home/WhatWeDo";
import WeEmpower from "../../components/home/WeEmpower";
import Testimonials from "../../components/home/Testimonials";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      direction={"column"}
      position="relative"
      bg={"transparent"}
      w={"100%"}
      pb={"64px"}
    >
      <BackToTop />

      <Header />

      <OurMission />

      <WhatWeDo />

      <WeEmpower />

      <Testimonials />
    </Flex>
  );
}
