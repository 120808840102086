import {
  Box,
  Heading,
  Stack,
  VStack,
  Flex,
  Text,
  Icon,
} from "@chakra-ui/react";
import weEmpowerImg from "../../assets/home/ourMission/mission.jpg";
import { FaArrowUp } from "react-icons/fa";

interface WeEmpowerContentProps {
  title: string;
  text: string;
}

export default function WeEmpower2() {
  const ITEMS = [
    {
      title: "Healthcare solutions",
      desc: "Evidence-based research and analysis that drives trailblazing healthcare solutions.",
    },
    {
      title: "Healthcare solutions",
      desc: "Evidence-based research and analysis that drives trailblazing healthcare solutions.",
    },
    {
      title: "Healthcare solutions",
      desc: "Evidence-based research and analysis that drives trailblazing healthcare solutions.",
    },
  ];
  return (
    <Box
      mt={0}
      px={[4, null, "64px"]}
      pb={"64px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box w="100%" position="relative">
        <VStack spacing={6}>
          <Stack direction={["column", null, "row"]} spacing={4}>
            <Box
              w={["100%", null, "50%"]}
              h={["auto", "600px"]}
              bgSize="cover"
              bgPosition="center"
              position="relative"
              bgRepeat="no-repeat"
              bgImage={`url(${weEmpowerImg})`}
              borderRadius={["none", "0px 0px 550px 0px"]}
              overflow="hidden"
            >
              <Box bg={"rgba(0, 0, 0, 0.4)"} h={["auto", "600px"]}>
                <Text
                  color={"white"}
                  fontSize={["16px", null, "18px"]}
                  fontWeight={["400", "bold"]}
                  py={8}
                  pl={[2, null, 8]}
                  px={[2, null, 8]}
                >
                  We are optimising the latest advancements in generative AI
                  empathy to provide access to quality resources and
                  life-changing solutions that address a broad spectrum of
                  healthcare needs. From therapy to drug discovery, our
                  technology is pioneering patient success stories!
                </Text>
              </Box>
            </Box>
            <Flex
              w={["100%", null, "50%"]}
              h={["fit-content", null, "600px"]}
              textAlign={"left"}
              direction={"column"}
              ml={[0, null, "24px"]}
            >
              <Heading
                fontWeight="700"
                fontSize={{ base: "18px", md: "24px", lg: "32px" }}
              >
                <Flex align={"center"}>
                  <Flex direction={"row"} align={"center"}>
                    <Heading
                      as={"h2"}
                      bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
                      bgClip="text"
                      color="transparent"
                      fontFamily="Poppins, sans-serif"
                      lineHeight="1.3"
                      fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
                      w={"fit-content"}
                    >
                      We empower
                    </Heading>
                    <Text
                      w={["36px", "52px"]}
                      h={["36px", "52px"]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                      bg="linear-gradient(144.12deg, #57AADE -0.73%, #205489 63.64%, #202053 128.02%)"
                      color="white"
                      ml={4}
                    >
                      <Icon as={FaArrowUp} boxSize={[4, 6]} />
                    </Text>
                  </Flex>
                </Flex>
              </Heading>

              {/* What we do content */}
              {ITEMS.map((item, index) => (
                <WeEmpowerContent
                  title={item.title}
                  text={item.desc}
                  key={`${item.title}-${index}`}
                />
              ))}
            </Flex>
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
}

function WeEmpowerContent({ title, text }: WeEmpowerContentProps) {
  return (
    <Box mt={4} fontSize={"14px"}>
      <Heading
        as={"h2"}
        my={4}
        fontWeight="700"
        fontStyle="italic"
        fontSize={{ base: "16px", md: "18px", lg: "20px" }}
        bgGradient="linear-gradient(180deg, #00C1B4 100%, #005B55 100%)"
        bgClip="text"
        color="transparent"
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        w={"fit-content"}
      >
        {title}
      </Heading>
      <Text>{text}</Text>
    </Box>
  );
}
