import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { grid, mockData } from "../../assets/home/testimonials/companies";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";

interface ArrowProps {
  onClick?: () => void;
  isNext?: boolean;
}

interface TestimonialProps {
  name: string;
  logo: string;
  position: string;
  review: string;
}

function SliderArrow({ onClick, isNext }: ArrowProps) {
  const position = useBreakpointValue({
    lg: isNext ? "10px" : "-10px",
    xl: isNext ? "15px" : "-15px",
  });

  return (
    <Icon
      zIndex={2}
      as={isNext ? FaArrowRightLong : FaArrowLeftLong}
      color={"gray.500"}
      boxSize={{ lg: "40px", xl: "48px" }}
      cursor={"pointer"}
      position={"absolute"}
      top={"50%"}
      right={isNext ? 0 : undefined}
      left={!isNext ? 0 : undefined}
      bg={"#F0F2F5"}
      transform={`translateY(-50%) translateX(${position})`}
      borderWidth={1}
      borderColor={"gray.200"}
      borderRadius={"12px"}
      p={{ lg: "12px", xl: "14px" }}
      _hover={{
        color: "#2088BE",
        bg: "blue.50",
        transition: "0.3s ease all",
      }}
      onClick={onClick}
    />
  );
}

function Testimonials() {
  return (
    <Flex
      direction={"column"}
      gap={10}
      pb={"100px"}
      maxW={{ lg: "920px", xl: "1100px", "2xl": "1160px" }}
      mx={"auto"}
    >
      {/* Section Heading */}
      <Heading
        as={"h2"}
        bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, #2088BE 100%)"
        bgClip="text"
        color="transparent"
        fontFamily="Poppins, sans-serif"
        lineHeight="1.3"
        fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
        w={"fit-content"}
        mx={"auto"}
      >
        What world's top companies are saying
      </Heading>

      {/* Section Content */}
      <Box className="slider-container">
        <Slider
          infinite
          speed={500}
          slidesToShow={2}
          slidesToScroll={1}
          lazyLoad={"progressive"}
          swipeToSlide={true}
          focusOnSelect={true}
          centerMode={true}
          className="center"
          centerPadding="0px"
          nextArrow={<SliderArrow isNext />}
          prevArrow={<SliderArrow />}
        >
          {mockData?.map((d) => (
            <Testimonial {...d} key={d.name} />
          ))}
        </Slider>
      </Box>
    </Flex>
  );
}

function Testimonial({ name, logo, position, review }: TestimonialProps) {
  return (
    <Flex
      mx={"auto"}
      p={10}
      w={{ lg: "440px", xl: "530px", "2xl": "560px" }}
      h={"275px"}
      borderWidth={1}
      borderColor={"gray.200"}
      borderRadius={"12px"}
      bg={"#F0F2F5"}
      backgroundImage={grid}
      backgroundPosition="cover"
      backgroundRepeat="no-repeat"
      direction={"column"}
      gap={6}
      cursor={"grab"}
      boxShadow={"sm"}
    >
      <Flex align={"center"} gap={6}>
        <Image
          loading="lazy"
          h={"auto"}
          w={{ lg: "100px", xl: "120px" }}
          alt="Bucksapp logo"
          src={logo}
        />
        <Flex direction={"column"} gap={1}>
          <Flex
            color={"#1e1e50"}
            lineHeight={1}
            fontWeight={"500"}
            fontSize={{ lg: "md", xl: "xl" }}
            fontFamily="Poppins, sans-serif"
          >
            {name}
          </Flex>
          <Flex fontSize={"14px"} fontWeight={"500"} color={"gray.500"}>
            {position}
          </Flex>
        </Flex>
      </Flex>
      <Text fontSize={{ lg: "14px", xl: "16px" }} color="#9292a5">
        {review}
      </Text>
    </Flex>
  );
}

export default Testimonials;
