import {
  Box,
  Heading,
  Stack,
  VStack,
  Flex,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import whatWeDoImg from "../../assets/home/whatWeDo/empathicai_vision_2.webp";
import { useNavigate } from "react-router-dom";
import { FaAngleRight, FaArrowRight, FaQuestion } from "react-icons/fa";

import { motion } from "framer-motion";
import { useState } from "react";

interface WhatWeDoContentProps {
  title: string;
  text: string;
}

export default function WhatWeDo() {
  const WHAT_WE_DO = [
    {
      title: "Empathic AI",
      desc: "Our AI bridges the gap between technology and human emotion to create impactful use cases.",
    },
    {
      title: "Scientific Innovation",
      desc: "Evidence-based research and analysis that drives trailblazing healthcare solutions.",
    },
    {
      title: "Healthcare Solutions",
      desc: "Evidence-based research and analysis that drives trailblazing healthcare solutions.",
    },
  ];
  return (
    <Box
      mt={0}
      px={[4, null, "64px"]}
      pb={"64px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box w="100%" position="relative">
        <VStack spacing={6}>
          <Stack direction={["column", null, "row"]} spacing={4}>
            <Flex
              w={["100%", null, "50%"]}
              h={["fit-content", null, "600px"]}
              textAlign={"left"}
              direction={"column"}
              ml={[0, null, "24px"]}
            >
              <Heading
                fontWeight="700"
                fontSize={{ base: "18px", md: "24px", lg: "32px" }}
              >
                <Flex align={"center"}>
                  <Flex direction={"row"} align={"center"}>
                    <Text
                      w={["36px", "52px"]}
                      h={["36px", "52px"]}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="50%"
                      bg="linear-gradient(144.12deg, #57AADE -0.73%, #205489 63.64%, #202053 128.02%)"
                      color="white"
                      mr={4}
                    >
                      <Icon as={FaQuestion} boxSize={[4, 6]} />
                    </Text>
                    <Heading
                      as={"h2"}
                      bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
                      bgClip="text"
                      color="transparent"
                      fontFamily="Poppins, sans-serif"
                      lineHeight="1.3"
                      fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
                      w={"fit-content"}
                    >
                      What we do
                    </Heading>
                  </Flex>
                </Flex>
              </Heading>

              {/* What we do content */}
              {WHAT_WE_DO.map((item, index) => (
                <WhatWeDoContent
                  title={item.title}
                  text={item.desc}
                  key={`${item.title}-${index}`}
                />
              ))}
            </Flex>
            <Box
              w={["100%", null, "50%"]}
              h={["auto", "600px"]}
              bgSize="cover"
              bgPosition="center"
              position="relative"
              bgRepeat="no-repeat"
              bgImage={`url(${whatWeDoImg})`}
              borderRadius={["none", "0px 0px 0px 550px"]}
              overflow="hidden"
            >
              <Box bg={"rgba(0, 0, 0, 0.4)"} h={["auto", "600px"]}>
                <Text
                  color={"white"}
                  fontSize={["16px", null, "18px"]}
                  fontWeight={["400", "bold"]}
                  py={8}
                  px={[2, null, 8]}
                >
                  We are optimising the latest advancements in generative AI
                  empathy to provide access to quality resources and
                  life-changing solutions that address a broad spectrum of
                  healthcare needs. From therapy to drug discovery, our
                  technology is pioneering patient success stories!
                </Text>
              </Box>
            </Box>
          </Stack>
        </VStack>
      </Box>
    </Box>
  );
}

function WhatWeDoContent({ title, text }: WhatWeDoContentProps) {
  // Hooks
  const navigate = useNavigate();

  const [hovered, setHovered] = useState<boolean>(false);

  const buttonVariants = {
    hovered: { x: 2, transition: { duration: 0.5 } },
    unhovered: { x: -2 },
  };

  return (
    <Box mt={4} fontSize={"14px"}>
      <Heading
        my={4}
        fontWeight="700"
        fontStyle="italic"
        fontSize={{ base: "16px", md: "18px", lg: "20px" }}
        style={{
          background: "linear-gradient(180deg, #00C1B4 100%, #005B55 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          display: "inline",
        }}
      >
        {title}
      </Heading>
      <Text>{text}</Text>
      <Button
        mt={4}
        size="md"
        color="#0F3F58"
        bg={"transparent"}
        fontWeight={"500"}
        borderRadius="30px"
        fontFamily="Poppins, sans-serif"
        fontSize={{ lg: "14px", "2xl": "16px" }}
        _hover={{ boxShadow: "0px 4px 9px 0px #0F3F5840" }}
        onClick={() => navigate("/")}
        rightIcon={
          <motion.div
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
            initial={hovered ? "hovered" : "unhovered"}
            animate={hovered ? "hovered" : "unhovered"}
            variants={buttonVariants}
          >
            {hovered ? (
              <FaArrowRight size={"16px"} />
            ) : (
              <FaAngleRight size={"16px"} />
            )}
          </motion.div>
        }
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        Find out more
      </Button>
    </Box>
  );
}
