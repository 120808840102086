import {
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import missionImg from "../../assets/home/ourMission/mission.png";
import AnimatedButton from "../../shared/AnimatedButton";
import { useNavigate } from "react-router-dom";

import { FaCircleCheck } from "react-icons/fa6";

function OurMission() {
  // Hooks
  const navigate = useNavigate();

  return (
    <Flex
      gap={10}
      color={"black"}
      pb={"100px"}
      w={"85%"}
      mx={"auto"}
      mt={{ lg: "-80px", xl: "-120px", "2xl": "-140px" }}
      position={"relative"}
    >
      <Flex
        position={"absolute"}
        top={0}
        bottom={0}
        w={{ lg: "360px", xl: "430px", "2xl": "510px" }}
        h={{ lg: "360px", xl: "430px", "2xl": "510px" }}
        background="linear-gradient(to top, #6985c1, transparent 100%)"
        opacity="0.7"
        // zIndex={-1}
        borderRadius={"50%"}
      />

      <Image
        src={missionImg}
        borderRadius={"50%"}
        w={{ lg: "360px", xl: "430px", "2xl": "510px" }}
        h={{ lg: "360px", xl: "430px", "2xl": "510px" }}
        objectFit={"cover"}
        objectPosition="center"
      />

      <Flex gap={8} direction={"column"} justify={"flex-end"} pb={"40px"}>
        <Flex gap={4} direction={"column"} justify={"flex-end"} maxW={"800px"}>
          <Heading
            as={"h2"}
            bgGradient="linear(to right, rgb(31.51, 32.14, 82.55) 0%, rgb(32, 136, 190) 100%)"
            bgClip="text"
            color="transparent"
            fontFamily="Poppins, sans-serif"
            lineHeight="1.3"
            fontSize={{ lg: "22px", xl: "26px", "2xl": "34px" }}
            w={"fit-content"}
          >
            Our mission
          </Heading>
          <Text
            fontStyle={"italic"}
            fontWeight={"500"}
            fontFamily="Poppins, sans-serif"
            fontSize={{ lg: "16px", "2xl": "18px" }}
            color={"#202053"}
          >
            {/* We are improving health access for patients worldwide. */}
            We are creating EmpathicAI, AI at the service of humanity.
          </Text>
          <Text
            color={"#202053"}
            lineHeight={"1.6"}
            fontSize={{ lg: "14px", "2xl": "16px" }}
          >
            {/* Our approach is rooted in empathy and responsibility, recognizing
            the profound healthcare challenges afflicting humanity. By
            accelerating the search for life-changing medications and
            therapeutic interventions, we aim to enhance the quality of life for
            people everywhere. */}
          </Text>
          <List
            spacing={3}
            pl={4}
            color={"#202053"}
            lineHeight={"1.6"}
            fontSize={{ lg: "14px", "2xl": "16px" }}
          >
            <ListItem>
              <ListIcon as={FaCircleCheck} color="#57AADE" />
              AI doing our bidding. Use cases that show empathy
            </ListItem>
            <ListItem>
              <ListIcon as={FaCircleCheck} color="#57AADE" />
              Amplifying Humans experts
            </ListItem>
            <ListItem>
              <ListIcon as={FaCircleCheck} color="#57AADE" />
              Ethics
            </ListItem>
          </List>
        </Flex>

        <AnimatedButton
          color={"#0e3e58"}
          fontFamily="Poppins, sans-serif"
          fontWeight={"500"}
          fontSize={{ lg: "14px", "2xl": "16px" }}
          h={"fit-content"}
          w={"fit-content"}
          p={3}
          px={4}
          borderRadius={"30px"}
          boxShadow={"0px 4px 9px #0e3e5840"}
          border={"none"}
          bg={"transparent"}
          onClick={() => navigate("/about")}
        >
          More about us
        </AnimatedButton>
      </Flex>
    </Flex>
  );
}

export default OurMission;
