export const colors = {
  background: "#f0f2f5",
  lightBackground: "#D5EEFF",

  primary: {
    100: "#F5FEFF",
    200: "#B0DBDD",
    300: "#6DAFB3",
    400: "#367D80",
    500: "#156064",
    600: "#06575B",
    700: "#004044",
    800: "#003033",
    900: "#001618",
  },
  secondary: {
    100: "#D5EEFF",
    200: "#A2CEEB",
    300: "#66AAD8",
    400: "#4196CE",
    500: "#1A86CE",
    600: "#166AA2",
    700: "#0D4C76",
    800: "#093654",
    900: "#041B2A",
  },

  neutral: {
    100: "#ffffff",
    200: "#F5F5F5",
    300: "#EAEAEA",
    400: "#E0E0E0",
    500: "#D6D6D6",
    600: "#CACACA",
    700: "#BEBEBE",
    800: "#B1AFAF",
    900: "#696969",
  },
  dark: {
    400: "#1E1502",
    500: "#305768",
    600: "#193e4e",
    700: "#0b2e3e",
    800: "#042230",
    900: "#031d2a",
  },

  highlight: {
    primary: "#2088BE",
    neutral: {
      100: "#F3F3F7",
      200: "#EBF0F9",
    },
  },

  gradient: {
    100: "#F3F3F7",
    200: "#EBF0F9",
  },

  none: "#D9594C",
  low: "#66AAD8",
  middle: "#367D80",
  high: "#198B7C",

  cosmiclatte: "#FFF6E1",
  gold: "#DDB967",
  bronze: "#DD8232",
  jasper: "#D9594C",

  gray: {
    50: "#f7fafc",
    100: "#edf2f7",
    200: "#e2e8f0",
    300: "#cbd5e0",
    400: "#a0aec0",
    500: "#718096",
    600: "#4a5568",
    700: "#2d3748",
    800: "#1a202c",
    900: "#171923",
  },
  red: {
    50: "#fff5f5",
    100: "#fed7d7",
    200: "#feb2b2",
    300: "#fc8181",
    400: "#f56565",
    500: "#e53e3e",
    600: "#c53030",
    700: "#9b2c2c",
    800: "#742a2a",
    900: "#5a2424",
  },
  orange: {
    50: "#fffaf0",
    100: "#feebc8",
    200: "#fbd38d",
    300: "#f6ad55",
    400: "#ed8936",
    500: "#dd6b20",
    600: "#c05621",
    700: "#9c4221",
    800: "#7b341e",
    900: "#652b19",
  },
  yellow: {
    50: "#fffff0",
    100: "#fefcbf",
    200: "#faf089",
    300: "#f6e05e",
    400: "#ecc94b",
    500: "#d69e2e",
    600: "#b7791f",
    700: "#975a16",
    800: "#744210",
    900: "#5f370e",
  },
  green: {
    50: "#f0fff4",
    100: "#c6f6d5",
    200: "#9ae6b4",
    300: "#68d391",
    400: "#48bb78",
    500: "#38a169",
    600: "#2f855a",
    700: "#276749",
    800: "#22543d",
    900: "#1c4532",
  },
  teal: {
    50: "#e6fffa",
    100: "#b2f5ea",
    200: "#81e6d9",
    300: "#4fd1c5",
    400: "#38b2ac",
    500: "#319795",
    600: "#2c7a7b",
    700: "#285e61",
    800: "#234e52",
    900: "#1d4044",
  },
  blue: {
    50: "#ebf8ff",
    100: "#bee3f8",
    200: "#90cdf4",
    300: "#63b3ed",
    400: "#4299e1",
    500: "#3182ce",
    600: "#2b6cb0",
    700: "#2c5282",
    800: "#2a4365",
    900: "#1A365D",
  },
  indigo: {
    50: "#ebf4ff",
    100: "#c3dafe",
    200: "#a3bffa",
    300: "#7f9cf5",
    400: "#667eea",
    500: "#5a67d8",
    600: "#4c51bf",
    700: "#434190",
    800: "#3c366b",
    900: "#2d2b55",
  },
  purple: {
    50: "#faf5ff",
    100: "#e9d8fd",
    200: "#d6bcfa",
    300: "#b794f4",
    400: "#9f7aea",
    500: "#805ad5",
    600: "#6b46c1",
    700: "#553c9a",
    800: "#44337a",
    900: "#322659",
  },
  pink: {
    50: "#fff5f7",
    100: "#fed7e2",
    200: "#fbb6ce",
    300: "#f687b3",
    400: "#ed64a6",
    500: "#d53f8c",
    600: "#b83280",
    700: "#97266d",
    800: "#702459",
    900: "#521B41",
  },
};
