import { useEffect } from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import BackToTop from "../../components/ui/BackToTop";
import Header from "../../components/about/Header";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction={"column"} bg={"transparent"}>
      <BackToTop />

      <Header />

      <Box width="100%" maxW="1200px" margin="auto" pt="64px" px={4}>
        <Heading
          fontWeight="700"
          fontSize={{ base: "18px", md: "24px", lg: "32px" }}
          style={{
            background: "linear-gradient(90deg, #202053 0.5%, #2088BE 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline",
          }}
        >
          About Us
        </Heading>
        <Divider mt={4} />

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>

        <Text my={6}>
          We are a venture builder using ethical and representative AI
          technology to pioneer groundbreaking health ventures that transform
          health journeys, empowering communities in the global south.
        </Text>

        <Text my={6}>
          At the core of our mission is the belief that leveraging cutting-edge
          technology can address complex healthcare challenges and make a
          meaningful impact on people's lives. We collaborate with diverse
          stakeholders, including healthcare professionals, researchers, and
          community leaders, to co-create solutions that are not only innovative
          but also culturally sensitive and inclusive.
        </Text>
      </Box>
    </Flex>
  );
}
